/* global lang */
import React from 'react';
import Peer from 'simple-peer';

async function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default class RemoteVideo extends React.Component {
  constructor(props) {
    super(props);

    this.state = { stream: false };

    this.peer = null;
    this.bindProps = () => {
      this.peer.on('signal', this.onSignal);
      this.peer.on('stream', this.onStream);
    };
    this.onSignal = this.onSignal.bind(this);
    this.onStream = this.onStream.bind(this);

    this.remoteVideo = React.createRef();
  }

  async componentDidMount() {

    let iceServers = [{
   urls: [ "stun:us-turn10.xirsys.com" ]
}, {
   username: "-VH9ZN7M95TnYbGTEhnZesuHee1bEl_ff6ivhHirhh8ztMv39yP7WzLhfMaaAhBUAAAAAGBQcsZlbW1hbnVlbA==",
   credential: "84a3387e-8635-11eb-a4db-0242ac140004",
   urls: [
       "turn:us-turn10.xirsys.com:80?transport=udp",
       "turn:us-turn10.xirsys.com:3478?transport=udp",
       "turn:us-turn10.xirsys.com:80?transport=tcp",
       "turn:us-turn10.xirsys.com:3478?transport=tcp",
       "turns:us-turn10.xirsys.com:443?transport=tcp",
       "turns:us-turn10.xirsys.com:5349?transport=tcp"
   ]
}];

    console.log('iceServers', iceServers)

    this.peer = new Peer({
      initiator: this.props.initiator,
      stream: this.props.stream,
      config: {
        iceServers: iceServers
      }
    });

    this.peer.on('error', err => console.log('error', err))

    this.bindProps();
    const peer = this.peer;
    this.props.client.registerPeerSignal(signal => {
      peer.signal(signal);
    });
    if (this.props.time != -1) {
      if (document.getElementById('counter')) {
        document.getElementById('counter_txt').innerHTML = this.props.time;
        let time = this.props.time;
        this.inter = setInterval(() => {
          time--;
          if (time < 0) {
            document.getElementById('counter_txt').innerHTML = lang.ended_chrono;

            document.getElementById('counter').classList.add('end');
          }
          else
            document.getElementById('counter_txt').innerHTML = lang.chrono.replace('%seconds%', time); //'Prepare to meet someone else in<br />' + time + ' sec.';
          if (time == 30) {
            document.getElementById('counter').classList.add('alerte');
            document.getElementById('counter').classList.add('beforeend');
          }
          if (time < 30) {
            document.getElementById('counter').classList.remove('alerte');
          }
        }, 1000)
      }
    }
  }


  sendSignal(signal) {
    this.peer.signal(signal);
  }

  componentWillUnmount() {
    clearInterval(this.inter);
    this.props.client.unRegisterPeerSignal();
    this.peer.destroy();
  }

  onSignal(data) {
    this.props.client.sendSignal({ toId: this.props.toId, signal: data });
  }

  onStream(stream) {
    this.setState({ stream: true });
    if ('srcObject' in this.remoteVideo.current) {
      this.remoteVideo.current.srcObject = stream;
    }
    else {
      this.remoteVideo.current.src = window.URL.createObjectURL(stream);
    }
  }

  render() {
    return (
      <React.Fragment>
      {this.state.time != -1 &&
          
          <div id="counter">
            <div id="counter_txt"></div>
          </div>
          
        }
        
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        
        }}
      >
        
        
        {this.state.stream ? (
          <video ref={this.remoteVideo} height="100%" autoPlay playsInline></video>
        ) : (
            <h3 dangerouslySetInnerHTML={{__html: lang.connecting}}></h3>
          )}
      </div>
      </React.Fragment>
    );
  }
}
