/* global lang */

const io = require('socket.io-client');

function getQueryParams(qs) {
    qs = qs.split('+').join(' ');

    var params = {},
        tokens,
        re = /[?&]?([^=]+)=([^&]*)/g;

    while (tokens = re.exec(qs)) {
        params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }

    return params;
}

export default function () {
  const port = process.env.NODE_ENV==='development' ? 'http://localhost:8080' : '';
  
  let id = getQueryParams(document.location.search).id;
  
  const socket = io.connect(port, {
      query: {
        id: id
      }
    });

  socket.on('error', err => console.log('socket error:', err));
   
  socket.on('turn', (data) => {
    console.log('turn', data);
    window.turn = data;
  });
  
  
  
  socket.on('disconnect', () => {
    document.querySelector('#root').style.display = 'none';
    document.body.style.padding = '40%'
    document.body.innerHTML = lang.disconnected;
    //document.location.href = document.location.href;
  })

  function getId() {
    return socket.id;
  }

  function registerChatHandler(onMessageReceived, onTyping, onStopTyping) {
    socket.on('message', onMessageReceived);
    socket.on('typing', onTyping);
    socket.on('stop typing', onStopTyping);
  }

  function registerCommonHandler(onConnected, onStrangerDisconnect) {
    socket.on('connected', onConnected);
    socket.on('stranger disconnected', onStrangerDisconnect);
  }

  function registerPeerSignal(onSignal) {
    socket.on('signal', onSignal);
  }

  function registerOnlineUsers(onOnline) {
    socket.on('online', onOnline);
  }

  function unRegisterChatHandler() {
    socket.off('message');
    socket.off('typing');
    socket.off('stop typing');
  }

  function unRegisterCommonHandler() {
    socket.off('connected');
    //socket.off('stranger disconnected');
  }

  function unRegisterPeerSignal(onSignal) {
    socket.off('signal', onSignal);
  }

  function message(msg) {
    socket.emit('message', msg);
  }

  function startTyping(toId) {
    socket.emit('typing', toId);
  }

  function stopTyping(toId) {
    socket.emit('stop typing', toId);
  }

  function startSearch(toId) {
    socket.emit('searching', toId);
  }

  function stop(toId) {
    socket.emit('stop', toId);
  }

  function sendSignal(signal) {
    socket.emit('signal', signal);
  }
  
  async function sendReady() {
    while(!window.turn) {
      await sleep(300);
    }
    console.log('je suis ready');
    socket.emit('ready');
  }
  
  
  function getOnlineUsers(cb) {
    socket.emit('online', cb);
  }
  
  function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

  return {
    getId,
    message,
    registerChatHandler,
    registerCommonHandler,
    registerPeerSignal,
    registerOnlineUsers,
    unRegisterChatHandler,
    unRegisterCommonHandler,
    unRegisterPeerSignal,
    startTyping,
    stopTyping,
    startSearch,
    stop,
    sendSignal,
    getOnlineUsers,
    sendReady
  };
}
