/* global lang */
import React from 'react';
import ReactDOM from 'react-dom';
import socket from './socket';
import Main from './components/Main';
import './css/index.css';

class App extends React.Component {
  constructor() {
    super();
    this.state = { client: socket() };
  }

  render() {
    return (
      <div className="root">
        <Main client={this.state.client} />
      </div>
    );
  }
}

var url = new URL(window.location.href);

var layout = url.searchParams.get("layout");

if (layout) {
  var link = document.createElement("link");
  link.href = layout;
  link.type = "text/css";
  link.rel = "stylesheet";
  link.media = "screen,print";

  document.getElementsByTagName("head")[0].appendChild(link);
}




var langScript = url.searchParams.get("lang");

if (!langScript) {
  langScript = '/default_lang'
}

var script = document.createElement('script');
script.onload = function() {
  ReactDOM.render(<App />, document.getElementById('root'));
  console.log(lang);
};
script.src = langScript;
document.head.appendChild(script);
